<template>
  <div id="page_not_found">
    <section class="bg-cl-light-white pt50 pb35 px20">
      <div class="container">
        <div class="row between-sm">
          <div class="col-sm-12 col-md-7">
            <h2 class="fs-big cl-light-gray weight-500 mt0 mb15 ">
              {{ $t('We looked everywhere.') }}
            </h2>
            <p class="h3 m0">
              {{ $t('It looks like no such page exists.') }}
            </p>
            <div class="flex mt100">
              <button-full :link="{ name: 'home' }" class="uppercase h5 mw300 shadow-1">
                {{ $t('Go to home page') }}
              </button-full>
            </div>
          </div>
          <div class="col-sm-12 col-md-5 hidden-xs">
            <img src="/assets/404-Egarderobe.png" :alt="$t('We looked everywhere.')" width="100%">
            <p class="align-center m0 oops cl-main">
              Oops!
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-cl-white pt35 px20 pb10">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <h2 class="h2 mt10 title">
              {{$t('Search product by category')}}
            </h2>
            <div class="row m0">
              <div class="col-xs-3 pl0 pr40 pb40" v-for="item in getCategoryFromMap" v-show="item.id !== 3 && item.id !== 206" :key="item.id">
                <router-link :to="categoryLink(item)">
                  <div class="brdr-1 brdr-cl-secondary-gray flex center-xs middle-xs category-item cl-black">
                    <span>{{ item.name }}</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n'
import ButtonFull from 'theme/components/theme/ButtonFull'
import { mapGetters } from 'vuex'
import groupBy from 'lodash-es/groupBy'
import { getThumbnailCategoryPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'PageNotFound',
  async asyncData ({ store, route, context }) {
    Logger.log('Entering asyncData for PageNotFound ' + new Date())()
    if (context) {
      context.output.cacheTags.add(`page-not-found`)
      context.server.response.statusCode = 404
    }

    await store.dispatch('homepage/loadBestsellers')
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  metaInfo () {
    return {
      htmlAttrs: { lang: config.storeViews[this.$store.state.storeView.storeCode].i18n.defaultLocale },
      title: this.$route.meta.title || i18n.t('404 Page Not Found'),
      meta: [
        {
          vmid: 'og:locale',
          property: 'og:locale',
          content: this.$store.state.storeView.storeCode ? config.storeViews[this.$store.state.storeView.storeCode].i18n.defaultLocale : ''
        },
        {
          vmid: 'description',
          property: 'description',
          content: this.$route.meta.description ? this.$route.meta.description : ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    categoriesMap () {
      return groupBy(this.getMenuCategories, 'parent_id')
    },
    getCategoryFromMap () {
      return this.categoriesMap[config.server.categoryIdsMap.mainCategoryId]
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    thumbnail (src) {
      return this.getThumbnail(src, 360, 360, 'category')
    }
  },
  components: {
    ButtonFull
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-main: color(main);
  .category-item {
    min-height: 100px;
    font-size: 14px;
    letter-spacing: 1px;
    color: #141E39;
    text-transform: uppercase;
  }
  .big-text {
    font-size: 46px;
  }
  .mw300 {
    max-width: 300px;
  }
  .title {
    padding-bottom: 30px;
    font-weight: 500;
    font-size: 32px;
    text-shadow: 0 0 #b3b3b3;
    color: #676767
  }
  .oops {
    font-weight: 500;
    font-size: 32px;
    text-shadow: 0 0 #b3b3b3;
    color: #676767
  }
  .button-full {
    padding: 15px 40px;
    min-width: 120px;
    background-color: $color-main;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-transform: inherit;
    border-radius: 30px;
    text-align: center;
  }
</style>
